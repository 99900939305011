<template>
  <div class="logout">
    <h1>{{ msg }}</h1>
    <a-button type="primary" @click="logoutDingtalk" size="large">
      <template #icon><PoweroffOutlined /></template>
      退出钉钉登陆
    </a-button>
    <a-button type="primary" @click="logoutThis" size="large">
      <template #icon><PoweroffOutlined /></template>
      注销本网站会话
    </a-button>
  </div>
</template>

<script>
// export default App;
// import { Button, Modal, Space } from 'ant-design-vue';
import { Modal } from 'ant-design-vue';
import { PoweroffOutlined } from '@ant-design/icons-vue';

const logoutThis = () => {
  var domainstr = ""
  var expstr = " expires=Thu, 01 Jan 1970 00:00:00 GMT;";
  if (navigator.userAgent.indexOf("Firefox") == -1) {
    domainstr = ""
  }
  domainstr = " domain=.dtyunxi.cn;"
  document.cookie = "dtyunxi.cn.auth.id=; path=/;" + domainstr + " SameSite=None; Secure;" + expstr
  document.cookie = "dtyunxi.cn.auth.user=; path=/;" + domainstr + " SameSite=None; Secure;" + expstr
  document.cookie = "dtyunxi.cn.auth.email=; path=/;" + domainstr + " SameSite=None; Secure;" + expstr
  document.cookie = "dtyunxi.cn.auth.sign=; path=/;" + domainstr + " SameSite=None; Secure;" + expstr
  Modal.success({
    content: '您已成功注销本网站'
  });
};

const logoutDingtalk = () => {
  Modal.success({
    content: '您将跳转到钉钉开发者后台, 点击页面右上角[退出]即退出钉钉登陆',
    onOk() { window.location.href = "https://open-dev.dingtalk.com/fe/old" }
  });
};

export default {
  name: 'LogOut',
  props: { msg: String },
  methods: { logoutThis, logoutDingtalk },
  components: { PoweroffOutlined }
}

</script>

<style scoped>
.ant-btn {
  margin: 50px 20px;
}
</style>
